<template>
    <div id="container" class="container router-view">
        <!-- Loading -->
        <loading-state
                v-if="loading"
                centered
                overlay
                :title="loadingMessage"
        />

        <b-modal class="goods-in"
                 body-class="d-flex flex-column py-0"
                 id="part-delivery-modal"
                 no-close-on-backdrop
                 no-close-on-esc
                 ok-title="Print Labels"
                 @ok="printLabels"
                 :title="partNumber"
        >
            <div class="row">
                <div class="col-md-12">
                    <ul>
                        <li><p>Stock Order Number: {{ stockOrderNumber }}</p></li>
                        <li><p>Delivery Number: {{ deliveryNumber }}</p></li>
                        <li><p>Sequence Number: {{ deliveryNumberSequence }}</p></li>
                        <li><input type="number" class="form-control" placeholder="Enter Quantity Received" v-model="quantity"></li>
                    </ul>
                </div>
            </div>
        </b-modal>

        <div class="row controls">
            <div class="col-md-6">
                <input type="text" class="form-control" placeholder="Printer Id" v-model="printerId">
            </div>
            <div class="col-md-6 text-center">
                <button class="btn btn-success form-control" id="grabFrame" @click="grabFrame">Run</button>
            </div>
            <div class="col-md-6">
                <div class="select form-control" style="display: none">
                    <select id="videoSource" @change="getStream"></select>
                </div>
                <input class="hidden" id="zoom" type="range" step="20">
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 text-center">
                <video autoplay playsinline class="hidden"></video>
            </div>
            <div class="col-md-12">
                <img class="hidden">
                <canvas class="hidden"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from "vuex";

    export default {
        data() {
            return {
                partNumber:null,
                deliveryNumberSequence:null,
                deliveryNumber:null,
                stockOrderNumber:null,
                quantity:"",
                printerId:null,
                imageData:null,
                constraints:null,
                imageCapture:null,
                mediaStream:null,
                canvas:null,
                img:null,
                video:null,
                videoSelect:null,
                zoomInput:null,
                loading:false,
                loadingMessage:"Loading"
            }
        },

        computed: {
            ...mapGetters([
                "user"
            ])
        },

        mounted() {
            this.setup();
            this.fetchDevices();
        },

        methods: {
            ...mapActions([
                "displayToast"
            ]),

            /**
             * Setup the links to the relevant html elements
             */
            setup() {
                this.canvas = document.querySelector('canvas');
                this.img = document.querySelector('img');
                this.video = document.querySelector('video');
                this.videoSelect = document.querySelector('select#videoSource');
                this.zoomInput = document.querySelector('input#zoom');
            },

            /**
             * Set whether we're loading with a message if needed
             * @param loading
             * @param loadingMessage
             */
            isLoading(loading, loadingMessage = "") {
                this.loading = loading;
                this.loadingMessage = loadingMessage;
            },

            /**
             * Fetch the media devices available then get the stream
             */
            fetchDevices() {
                navigator.mediaDevices.enumerateDevices()
                    .then(this.gotDevices)
                    .catch(error => {
                        console.log('enumerateDevices() error: ', error);
                    })
                    .then(this.getStream);
            },

            /**
             * Received devices from mediaDevices
             * @param deviceInfos
             */
            gotDevices(deviceInfos) {
                for (let i = 0; i !== deviceInfos.length; ++i) {
                    let deviceInfo = deviceInfos[i];
                    console.log('Found media input or output device: ', deviceInfo);
                    let option = document.createElement('option');
                    option.value = deviceInfo.deviceId;
                    if (deviceInfo.kind === 'videoinput') {
                        option.text = deviceInfo.label || 'Camera ' + (this.videoSelect.length + 1);
                        this.videoSelect.appendChild(option);
                    }
                }
            },

            /**
             * Get stream from the media device
             */
            getStream() {
                if (this.mediaStream) {
                    this.mediaStream.getTracks().forEach(track => {
                        track.stop();
                    });
                }
                let videoSource = this.videoSelect.value;
                this.constraints = {
                    video: {
                        deviceId: videoSource ? {exact: videoSource} : undefined,
                        // facingMode: {
                        //     exact: 'environment'
                        // }
                    }
                };
                navigator.mediaDevices.getUserMedia(this.constraints)
                    .then(this.gotStream)
                    .catch(error => {
                        console.log('getUserMedia error: ', error);
                    });
            },

            /**
             * Load media stream
             * @param stream
             */
            gotStream(stream) {
                console.log('getUserMedia() got stream: ', stream);
                this.mediaStream = stream;
                this.video.srcObject = stream;
                this.video.classList.remove('hidden');
                this.imageCapture = new ImageCapture(stream.getVideoTracks()[0]);
                this.getCapabilities();
            },

            /**
             * Get the capabilities of the image capture
             */
            getCapabilities() {
                let self = this;
                this.imageCapture.getPhotoCapabilities().then(function(capabilities) {
                    console.log('Camera capabilities:', capabilities);
                    if (capabilities.zoom && capabilities.zoom.max > 0) {
                        self.zoomInput.min = capabilities.zoom.min;
                        self.zoomInput.max = capabilities.zoom.max;
                        self.zoomInput.value = capabilities.zoom.current;
                        self.zoomInput.classList.remove('hidden');
                    }
                }).catch(function(error) {
                    console.log('getCapabilities() error: ', error);
                });
            },

            /**
             * Reset the data
             */
            reset() {
                this.partNumber = null;
                this.deliveryNumber = null;
                this.deliveryNumberSequence = null;
                this.stockOrderNumber = null;
                this.quantity = "";
            },

            /**
             *  Grab a snapshot of the video stream
             */
            grabFrame() {
                let self = this;
                this.isLoading(true, "Fetching Delivery");
                this.reset();
                this.imageCapture.grabFrame().then(function(imageBitmap) {
                    console.log('Grabbed frame:', imageBitmap);
                    self.canvas.width = imageBitmap.width;
                    self.canvas.height = imageBitmap.height;
                    self.canvas.getContext('2d').drawImage(imageBitmap, 0, 0);
                    let result = self.canvas.toDataURL().replace("data:image/png;base64,", "");
                    self.sendImageToGoogle(result);
                }).catch(function(error) {
                    console.log('grabFrame() error: ', error);
                });
            },

            /**
             * TODO: dead code, remove.
             * Take a photo of the video stream
             */
            takePhoto() {
                let self = this;
                this.reset();
                this.imageCapture.takePhoto().then(function(blob) {
                    self.imageData = blob;
                    self.convertImageToBase64();
                    console.log('Took photo:', blob);
                    self.img.classList.remove('hidden');
                    self.img.src = URL.createObjectURL(blob);
                }).catch(function(error) {
                    console.log('takePhoto() error: ', error);
                });
            },

            /**
             * TODO: dead code, only used by takePhoto. Remove.
             * Convert the image data to base64 then send to google (Only need to use if using the takePhoto() function
             */
            convertImageToBase64() {
                let self = this;
                let file = self.imageData;
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                    let result = reader.result.replace("data:image/jpeg;base64,", "");
                    self.sendImageToGoogle(result);
                };
                reader.onerror = function (error) {
                    console.log('Error: ', error);
                };
            },

            /**
             * Send the image to google to detect text
             * @param imageData
             */
            sendImageToGoogle(imageData) {
                let apiKey = "AIzaSyBVjrDgBoWNl2jXDoxqlUZsyC_pXmudvXM";

                this.$http.post(`https://vision.googleapis.com/v1/images:annotate?key=${apiKey}`, {
                    "requests":[
                        {
                            "image":{
                                "content":imageData
                            },
                            "features":[
                                {
                                    "type":"TEXT_DETECTION",
                                    "maxResults":1
                                }
                            ]
                        }
                    ]
                })
                .then(response => {
                    console.log(response.data);
                    if (response.data.responses && response.data.responses.length > 0) {
                      const found = response.data.responses[0];
                      if (found.textAnnotations) {
                        this.processAnnotations(found.textAnnotations);
                        return;
                      }
                    }
                    this.displayToast({text: 'Please try again - no data found', type: 'info', duration:1000});
                    this.isLoading(false);
                })
            },

            /**
             * Process the text received back from google detected in the image
             * @param annotations
             */
            processAnnotations(annotations) {
                let partNumberIndex = "";
                let deliveryNumberIndex = "";
                let stockOrderNumberIndex = "";

                annotations.forEach((annotation, index) => {

                    if (annotation.description === "Part:") {
                        partNumberIndex = index + 1;
                    }

                    if (annotation.description === "Deli.NO:") {
                        deliveryNumberIndex = index + 1;
                    }

                    if (annotation.description === "Cust.NO:" || annotation.description === "ust.NO:") {
                        stockOrderNumberIndex = index + 1;
                    }

                    if (index === partNumberIndex) {
                        this.partNumber = annotation.description;
                    }

                    if (index === deliveryNumberIndex) {
                        this.deliveryNumber = this.deliveryNumberFromDescription(annotation.description);
                        this.deliveryNumberSequence = this.deliveryNumberSequenceFromDescription(annotation.description);
                    }

                    if (index === stockOrderNumberIndex) {
                        this.stockOrderNumber = annotation.description;
                    }
                });

                if (this.partNumber && this.stockOrderNumber && this.deliveryNumber) {
                    this.$bvModal.show('part-delivery-modal');
                } else {
                    this.displayToast({text: 'Please try again - coulding find delivery', type: 'error', duration:1000});
                }

                this.isLoading(false);
            },

            /**
             * Trim the delivery number from the label as it contains extra characters
             * @param deliveryNumberDescription
             * @returns {string}
             */
            deliveryNumberFromDescription(deliveryNumberDescription) {
                let length = 10;
                return deliveryNumberDescription.substring(0, length);
            },

            /**
             * Get the sequence number from the delivery number string
             * Last 6 characters of the string
             * @param deliveryNumberDescription
             * @returns {string}
             */
            deliveryNumberSequenceFromDescription(deliveryNumberDescription) {
                return deliveryNumberDescription.slice(deliveryNumberDescription.length - 6)
            },

            /**
             * Print the stock labels
             */
            printLabels() {
                this.isLoading(true, "Printing Labels");

                this.$http.post(`/api/v4/stock/orders/deliveries/print-node-labels`, {
                    delivery_number: this.deliveryNumber,
                    delivery_number_sequence: this.deliveryNumberSequence,
                    part_number: this.partNumber,
                    stock_order_id: this.stockOrderNumber,
                    quantity: this.quantity,
                    printer_id: this.printerId
                })
                .then(response => {
                    if (response.data.data.status_message === "Created") {
                        this.displayToast({text: 'Labels are printing', type: 'success', duration:1000});
                    }
                    this.isLoading(false);
                    console.log(response);
                })
                .catch(error => {
                    this.isLoading(false);
                    this.displayToast({text: error.response.data.messages, type: 'error', duration:3000});
                    console.log(error);
                })
            }

        }
    }
</script>
<style scoped>
  .hidden {
    display: none;
  }

  video {
    height: 800px;
    border: 3px solid white;
    border-radius: 6px;
  }

  .controls {
    margin-bottom: 20px;
  }

  ul {
    padding-left: 0;
  }

  ul li {
    list-style: none;
  }
</style>
